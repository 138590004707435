@import 'src/styles/variables';
@import 'src/styles/mixins';

.main {
  width: 100%;
  height: 100vh;
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

.button {
  width: 400px;
  height: 70px;

  @include vp-840 {
    width: 100%;
  }
}
